.DateRangePicker_picker__portal {
  .DayPicker {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
    min-width: 300px !important;
    > div {
      @media (max-width: 600px) {
        scale: 1.2 !important;
      }
    }
  }
}

.DayPicker__withBorder {
  border-radius: 8px !important;
}

.DayPicker_transitionContainer {
  border-radius: 8px !important;
}

.CalendarDay,
.CalendarDay__default {
  padding: 0 !important;
  border: none !important;

  &:hover {
    padding: 0 !important;
    border: none !important;
  }
}

.CalendarMonth_table {
  border-collapse: separate !important;
  border-spacing: 0 4px !important;
}

.CalendarMonth_caption {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 12px;
}

.DayPicker_weekHeader_li {
  font-weight: 500;
  color: #666;
}

.DayPickerNavigation_button {
  svg {
    width: 24px;
    height: 24px;
  }
}

.CalendarDay--selected,
.CalendarDay--selected-span,
.CalendarDay--selected-start,
.CalendarDay--selected-end,
.CalendarDay__selected,
.CalendarDay__selected_span,
.CalendarDay__selected_start,
.CalendarDay__selected_end {
  background: #e9f0fe !important;
  border-radius: 0 !important;
  border: none !important;
}

.CalendarDay--selected-start,
.CalendarDay__selected_start {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}

.CalendarDay--selected-end,
.CalendarDay__selected_end {
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}

.CalendarDay--hovered-span,
.CalendarDay__hovered_span,
.CalendarDay--after-hovered-start,
.CalendarDay__after_hovered_start {
  background: #dae7ff !important;
  border-radius: 0 !important;
}

/* day-container: positions child elements (like the dot) correctly */
.day-container {
  position: relative;
  text-align: center;
}

/* Circle for start/end days */
.start-end-circle {
  width: 36px;
  height: 35px;
  border-radius: 50%;
  background-color: #2e63f9;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto; /* horizontally center within day-container */
}

/* Green dot for discounted days */
.discounted-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #28a745;
  position: absolute;
  bottom: 4px;
  right: 4px;
}
